import React from "react";

const AddToContactsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 330 330"
    fill="currentColor"
    {...props}
  >
    <path
      d="M305,0H105C69.159,0,40,29.159,40,65v15H25c-8.284,0-15,6.716-15,15s6.716,15,15,15h15v40H25
		c-8.284,0-15,6.716-15,15s6.716,15,15,15h15v40H25c-8.284,0-15,6.716-15,15s6.716,15,15,15h15v15c0,35.841,29.159,65,65,65H305
		c8.284,0,15-6.716,15-15V15C320,6.716,313.284,0,305,0z M290,300H105c-19.299,0-35-15.701-35-35V65c0-19.299,15.701-35,35-35H290
		V300z"
    />
    <circle cx="180" cy="129.999" r="45" />
    <path d="M180,174.999c-38.66,0-70,31.338-70,70h140C250,206.337,218.66,174.999,180,174.999z" />
  </svg>
);
export default AddToContactsIcon;
