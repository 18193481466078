import React from "react";

const PhoneIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 330 330"
    fill="currentColor"
    {...props}
  >
    <path
      d="M142.1,105.6c5.8,5.9,5.8,15.4-0.1,21.2L111.3,157l60.9,61.8l30.7-30.2c2.8-2.8,6.7-4.3,10.6-4.3
		s7.8,1.6,10.6,4.5l60.9,61.8c5.8,5.9,5.8,15.4-0.1,21.2l-41.3,40.7c-18.9,18.6-48.6,22.8-83.9,11.9c-32-10-65.5-31.6-94.5-60.9
		c-40.4-41-65.3-91-65.1-130.7c0.1-19.8,6.5-36.5,18.4-48.3C19.3,83.8,60,43.7,60,43.7c2.8-2.8,6.7-4.3,10.6-4.3
		c4,0,7.8,1.6,10.6,4.5L142.1,105.6z M39.8,105.7c-6.2,6.2-9.4,15.3-9.5,27.1c-0.2,31.5,21.9,74.4,56.4,109.4
		c25.5,25.9,54.7,44.8,82,53.3c23.8,7.4,43.4,5.7,53.9-4.6l30.6-30.2l-39.9-40.4l-30.7,30.2c-2.8,2.8-6.7,4.3-10.6,4.3
		c-4,0-7.8-1.6-10.6-4.5l-82-83.1c-5.8-5.9-5.8-15.4,0.1-21.2l30.7-30.2L70.3,75.6C58.3,87.5,40.2,105.3,39.8,105.7z"
    />
    <path
      d="M283.9,47.7c30,30.5,46.4,70.8,46.1,113.6c-0.1,8.3-6.8,15-15.1,14.9c-8.3-0.1-15-6.8-14.9-15.1
			c0.2-34.8-13.1-67.5-37.5-92.3c-24.4-24.7-57-38.5-91.8-38.7c-8.3-0.1-15-6.8-14.9-15.1C156,6.7,162.7,0,171,0
			C213.8,0.3,253.9,17.3,283.9,47.7z"
    />
    <path
      d="M170.4,88.1c-8.3-0.1-15-6.8-14.9-15.1c0.1-8.3,6.8-15,15.1-14.9c56.3,0.4,101.8,46.5,101.4,102.8
		c-0.1,8.3-6.8,15-15.1,14.9c-8.3-0.1-15-6.8-14.9-15.1C242.3,120.9,210.2,88.4,170.4,88.1z"
    />
  </svg>
);
export default PhoneIcon;
