import React from "react";

const MessageIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 330 330"
    fill="currentColor"
    {...props}
  >
    <path
      d="M315,0H15C6.716,0,0,6.716,0,15v230c0,8.284,6.716,15,15,15h45v55c0,5.593,3.112,10.722,8.073,13.305
		c2.18,1.135,4.557,1.695,6.926,1.695c3.024,0,6.034-0.913,8.604-2.711L179.729,260H315c8.284,0,15-6.716,15-15V15
		C330,6.716,323.284,0,315,0z M300,230H175c-3.077,0-6.081,0.947-8.602,2.711L90,286.19V245c0-8.284-6.716-15-15-15H30V30h270V230z"
    />
    <path d="M90,175h160c8.284,0,15-6.716,15-15s-6.716-15-15-15H90c-8.284,0-15,6.716-15,15S81.716,175,90,175z" />
    <path d="M90,105h160c8.284,0,15-6.716,15-15s-6.716-15-15-15H90c-8.284,0-15,6.716-15,15S81.716,105,90,105z" />
  </svg>
);
export default MessageIcon;
