import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import "./styles/emojis.scss";
import "./styles/datepicker.css";
import "./styles/transitions.css";
import "./styles/vazir-font.scss";
import App from "./App";
import MainLoader from "./components/MainLoader";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<MainLoader />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);