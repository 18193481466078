import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import profilePicturePlaceholder from "../assets/profile-picture-placeholder.svg";
import Image from "./Image";

const ProfilePicture = ({ image, className, ...rest }) => {
  const { t } = useTranslation();

  const altText = t("picture");
  const classes = clsx("bg-gray-200 border rounded-full", className);

  if (image) {
    return <Image className={classes} image={image} alt={altText} {...rest} />;
  }

  return (
    <img
      className={classes}
      src={profilePicturePlaceholder}
      alt={altText}
      {...rest}
    />
  );
};

export default ProfilePicture;
