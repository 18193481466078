import React from "react";
import { useField, useFormikContext } from "formik";
import clsx from "clsx";
import CloseIcon from "./icons/CloseIcon";
import { useTranslation } from "react-i18next";
import Select from "./Select";

const SocialMediaInput = ({
  name,
  links,
  disabled,
  className,
  selectProps,
  inputProps,
  buttonProps,
  ...rest
}) => {
  const { i18n } = useTranslation();
  const { getFieldMeta } = useFormikContext();
  const [field, { error, touched }] = useField(`${name}.Value`);
  const { value } = getFieldMeta(`${name}.LinkId`);

  return (
    <div className="flex space-x-2">
      <div className="w-36">
        <Select name={`${name}.LinkId`} disabled={disabled}>
          {links.map((link) => (
            <option key={`link-${link.Id}`} value={link.Id}>
              {
                link.Details.find((item) => item.Language === i18n.language)
                  .Title
              }
            </option>
          ))}
        </Select>
      </div>
      <div className={clsx("relative flex-grow", className)}>
        <input
          name={name}
          disabled={disabled}
          className={clsx(
            "resize-none bg-white border py-2 pl-3 pr-10 border-gray-300 focus:outline-none focus:border-gray-400 disabled:bg-gray-100 disabled:opacity-60 block w-full",
            {
              "border-red-600 focus:border-red-700": error && touched,
            }
          )}
          placeholder={
            links
              .find((item) => item.Id === parseInt(value))
              .Details.find((item) => item.Language === i18n.language).Hint
          }
          {...field}
          {...inputProps}
        />
        <div className="absolute right-3 inset-y-0 flex items-center">
          <button type="button" disabled={disabled} {...buttonProps}>
            <CloseIcon className="w-6" />
          </button>
        </div>
      </div>
      {error && touched && <div className="text-red-600">{error}</div>}
    </div>
  );
};

export default SocialMediaInput;
