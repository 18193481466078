import React, { useMemo, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Form } from "formik";
import * as yup from "yup";
import Button from "../components/Button";
import Input from "../components/Input";
import InputGroupList from "../components/InputGroupList";
import Label from "../components/Label";
import Link from "../components/Link";
import FormikI18n from "../components/FormikI18n";
import AuthLayout from "../layouts/AuthLayout";
import { useRepo } from "../hooks/repo";
import { useMutation } from "react-query";
import Alert from "../components/Alert";

const SignUpPage = () => {
  const { t } = useTranslation();
  const { repo, setAuthToken } = useRepo();
  const {
    isError,
    error,
    mutateAsync,
    isSuccess,
    data,
  } = useMutation((credentials) => repo.mutateRegister(credentials));

  useEffect(() => {
    if (isSuccess) {
      setAuthToken(data);
    }
  }, [setAuthToken, isSuccess, data]);

  const submitHandler = (data) => mutateAsync(schema.cast(data));

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().lowercase().email().required().label(t("email")),
        password: yup.string().min(8).required().label(t("password")),
      }),
    [t]
  );

  return (
    <>
      <Helmet>
        <title>{t("create-account")}</title>
      </Helmet>
      <AuthLayout>
        <FormikI18n
          initialValues={{ email: "", password: "" }}
          onSubmit={submitHandler}
          validationSchema={schema}
        >
          {({ isSubmitting }) => (
            <InputGroupList component={Form} noValidate>
              <div>
                <Label htmlFor="email-input">{t("email")}</Label>
                <Input
                  id="email-input"
                  name="email"
                  type="email"
                  dir="auto"
                  disabled={isSubmitting}
                  block
                />
              </div>
              <div label={t("password")}>
                <Label htmlFor="password-input">{t("password")}</Label>
                <Input
                  id="password-input"
                  name="password"
                  type="password"
                  disabled={isSubmitting}
                  block
                />
              </div>
              {isError && <Alert variant="danger">{t(error.message)}</Alert>}
              <div>
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting && t("creating-account")}
                  block
                  type="submit"
                >
                  {t("create-account")}
                </Button>
              </div>
              <p>
                {t("already-have-account")}
                <Link to="/login" className="ml-1">
                  {t("login")}
                </Link>
              </p>
            </InputGroupList>
          )}
        </FormikI18n>
      </AuthLayout>
    </>
  );
};

export default SignUpPage;
