import clsx from "clsx";
import React, { forwardRef } from "react";

const Backdrop = forwardRef(({ className, ...rest }, ref) => (
  <div
    className={clsx("fixed inset-0 bg-black bg-opacity-75 z-10", className)}
    {...rest}
    ref={ref}
  />
));

export default Backdrop;
