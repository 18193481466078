import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, Link as RouterLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import Image from "../components/Image";
import Link from "../components/Link";
import QrIcon from "../components/icons/QrIcon";
import LinkIcon from "../components/icons/LinkIcon";
import PhoneIcon from "../components/icons/PhoneIcon";
import MessageIcon from "../components/icons/MessageIcon";
import AddToContactsIcon from "../components/icons/AddToContactsIcon";
import EmailIcon from "../components/icons/EmailIcon";
import Container from "../components/Container";
import QrCode from "qrcode.react";
import vCardJs from "vcards-js";
import MainLoader from "../components/MainLoader";
import { useAuth } from "../hooks/auth";
import ProfilePicture from "../components/ProfilePicture";
import Backdrop from "../components/Backdrop";
import MainLayout from "../layouts/MainLayout";
import useCardsQuery from "../hooks/useCardsQuery";
import useCardQuery from "../hooks/useCardQuery";
import AddIcon from "../components/icons/AddIcon";
import Button from "../components/Button";
import { useMutation, useQuery } from "react-query";
import { useRepo } from "../hooks/repo";
import Yay from "../components/emojis/Yay";
import Wow from "../components/emojis/Wow";
import { CSSTransition } from "react-transition-group";
import ScrollLock from "react-scrolllock";


const QrModel = ({ open, onClose, slug, ...rest }) => {
  const { t } = useTranslation();

  return (
    <>
      <ScrollLock isActive={open} />
      <CSSTransition in={open} classNames="fade" timeout={300}>
        <Backdrop className="backdrop" onClick={onClose} />
      </CSSTransition>
      <CSSTransition in={open} classNames="slide" timeout={250}>
        <div
          className="qr-card fixed inset-0 overflow-auto z-10"
          onClick={onClose}
        >
          <Container
            className="min-h-full flex flex-col items-center justify-center"
            maxWidth="xs"
          >
            <div className="flex-shrink-0 rounded overflow-hidden my-8 mx-5">
              <QrCode
                className="w-full"
                renderAs="svg"
                width={null}
                height={null}
                includeMargin
                value={`${window.location.protocol}//${window.location.host}/${slug}`}
              />
            </div>
            <div className="text-xs text-white bg-black bg-opacity-50 py-1 px-4 my-8 rounded-full">
              {t("touch-to-dismiss")}
            </div>
          </Container>
        </div>
      </CSSTransition>
    </>
  );
};

const CardPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const { isLoggedIn } = useAuth();
  const { repo } = useRepo();
  const { t, i18n } = useTranslation();
  const cardQuery = useCardQuery(id);
  const cardsQuery = useCardsQuery();
  const activateMutation = useMutation(() => repo.mutateActivateCard({ id }));

  const card = cardQuery?.data;
  const cards = cardsQuery?.data;

  const avatarQuery = useQuery(
    ["avatar", card?.Avatar?.Filename],
    () => repo.url2base64(card.Avatar.SrcSet["1x"]),
    { enabled: Boolean(card?.Avatar) }
  );
  const [vcardBlob, setVCardBlob] = useState(null);
  const [qrModelOpen, setQrModelOpen] = useState(false);

  useEffect(() => {
    if (card) {
      const vcard = vCardJs();
      const { email, phone } = groupBy(card.Contacts, "ContactType");
      const siteUrl = card.SiteUrl && (/^([a-zA-Z][a-zA-Z0-9+\-.]*):\/\//.test(card.SiteUrl) ? card.SiteUrl : `http://${card.SiteUrl}` );

      const facebookUrl = card.Links?.find((link) => link.Link.Id === 3)?.Url;
      const twitterUrl = card.Links?.find((link) => link.Link.Id === 19)?.Url;
      const linkedInUrl = card.Links?.find((link) => link.Link.Id === 4)?.Url;

      vcard.formattedName = card.Nickname;

      if (card.Bio) vcard.title = card.Bio;
      if (siteUrl) vcard.url = siteUrl;
      if (phone) vcard.cellPhone = phone.map((item) => item.Value);
      if (email) vcard.email = email.map((item) => item.Value);
      if (facebookUrl) vcard.socialUrls.facebook = facebookUrl;
      if (linkedInUrl) vcard.socialUrls.linkedIn = linkedInUrl;
      if (twitterUrl) vcard.socialUrls.twitter = twitterUrl;

      if (avatarQuery.data) {
        vcard.photo.embedFromString(avatarQuery.data, "JPEG");
      }

      setVCardBlob(
        new Blob([vcard.getFormattedString()], {
          type: "text/vcard",
        })
      );
    }
  }, [card, i18n, setVCardBlob, avatarQuery.data]);

  const openQrModel = () => setQrModelOpen(true);
  const closeQrModel = () => setQrModelOpen(false);

  if (cardQuery.isLoading || cardsQuery.isLoading) {
    return <MainLoader />;
  }

  if (cardQuery.isError || cardsQuery.isError) {
    // TODO: Error Page
    return "error occurred";
  }

  const isOwner = Boolean(cards && cards.find((item) => item.Id === card.Id));

  if (card.IsAvailable) {
    const redirectToSignup = () => {
      localStorage.setItem("card-to-activate", id);
      history.push({ pathname: "/signup" });
    };

    const activateCard = () => {
      activateMutation
        .mutateAsync()
        .then(() => cardsQuery.refetch())
        .then(() => history.push(`/${id}/settings`));
    };

    const getActivateCard = () => {
      if (isLoggedIn) {
        return (
          <div className="text-center">
            <div className="mt-10 mb-8">
              <Wow />
            </div>
            <div className="px-6">
              <h1 className="text-2xl">{t("ready-to-activate-card")}</h1>
              <p className="text-sm text-gray-600 mt-2">
                {t("ready-to-activate-card-description")}
              </p>
            </div>
            <Button
              className="my-8 inline-block"
              onClick={activateCard}
              loading={activateMutation.isLoading && t("activating-card")}
              disabled={activateMutation.isLoading}
            >
              {t("activate-card-now")}
            </Button>
          </div>
        );
      }

      return (
        <div className="text-center">
          <div className="mt-10 mb-8">
            <Yay />
          </div>
          <div className="px-6">
            <h1 className="text-2xl">{t("signup-to-activate-card")}</h1>
            <p className="text-sm text-gray-600 mt-2">
              {t("signup-to-activate-card-description")}
            </p>
          </div>
          <Button onClick={redirectToSignup} className="my-8 inline-block">
            {t("signup-to-activate")}
          </Button>
        </div>
      );
    };

    return (
      <>
        <Helmet>
          <title>{t("activate-card")}</title>
        </Helmet>
        <MainLayout>
          <Container className="px-2">{getActivateCard()}</Container>
        </MainLayout>
      </>
    );
  }

  const { email, phone } = groupBy(card.Contacts, "ContactType");
  const siteUrl = card.SiteUrl && (/^([a-zA-Z][a-zA-Z0-9+\-.]*):\/\//.test(card.SiteUrl) ? card.SiteUrl : `http://${card.SiteUrl}` );

  return (
    <>
      <Helmet>
        <title>{card.Nickname || t("unnamed")}</title>
      </Helmet>
      {/* Qr Model */}
      <QrModel open={qrModelOpen} onClose={closeQrModel} slug={id} onClick={closeQrModel} />
      <MainLayout>
        {/* Picture */}
        <div className="relative p-2 my-6 mx-auto">
          <ProfilePicture
            className="shadow-xl w-28 h-28"
            image={card.Picture}
            alt={t("picture")}
          />
          <div
            className="absolute bottom-0 right-0 bg-white hover:bg-gray-100 cursor-pointer rounded-full shadow-md text-3xl p-2"
            onClick={openQrModel}
          >
            <QrIcon />
          </div>
        </div>
        {/* Details */}
        <Container className="text-center px-6">
          <div className="text-lg font-bold">{card.Nickname || t("unnamed")}</div>
          {card.Bio && (
            <div className="text-sm text-gray-500 mt-0.5 overflow-ellipsis overflow-hidden">
              {card.Bio}
            </div>
          )}
          {siteUrl && (
            <div className="mt-2">
              <a
                dir="ltr"
                className="inline-flex justify-center items-center font-bold text-blue-500 hover:underline"
                href={siteUrl}
                target="_blank"
                rel="noreferrer"
              >
                <LinkIcon className="flex-shrink-0 mr-0.5" />
                <div className="truncate">{new URL(siteUrl).hostname}</div>
              </a>
            </div>
          )}
        </Container>
        {/* Actions */}
        <div className="mt-6 border-t border-b">
          <Container className="flex h-12">
            {phone && (
              <>
                <a
                  className="flex-grow flex items-center hover:bg-gray-100"
                  href={`tel:${phone[0].Value}`}
                >
                  <PhoneIcon className="mx-auto w-5 opacity-80" />
                </a>
                <a
                  className="flex-grow flex items-center hover:bg-gray-100"
                  href={`sms:${phone[0].Value}`}
                >
                  <MessageIcon className="mx-auto w-5 opacity-80" />
                </a>
              </>
            )}
            <a
              className="flex-grow flex items-center hover:bg-gray-100"
              href={vcardBlob && window.URL.createObjectURL(vcardBlob)}
              download={`${id}.cvf`}
            >
              <AddToContactsIcon className="mx-auto w-5 opacity-80" />
            </a>
            {email && (
              <a
                className="flex-grow flex items-center hover:bg-gray-100"
                href={`mailto:${email[0].Value}`}
              >
                <EmailIcon className="mx-auto w-5 opacity-80" />
              </a>
            )}
          </Container>
        </div>
        {/* Social Links */}
        <div className="flex-grow py-2 bg-gray-50">
          {card.Links || isOwner ? (
            <Container className="px-6">
              <div className="flex flex-wrap -mx-3">
                {card.Links &&
                  card.Links.map((link, index) => {
                    const linkLocalized = keyBy(link.Link.Details, "Language")[
                      i18n.language
                    ];
                    return (
                      <div key={index} className="w-1/4 p-2.5">
                        <Link
                          component="a"
                          href={link.Url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Image
                            className="w-full"
                            image={link.Link.Icon}
                            alt={linkLocalized.Description}
                          />
                          <div className="mt-1 text-xs text-center truncate">
                            {linkLocalized.Title}
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                {isOwner && (
                  <div className="w-1/4 p-2.5">
                    <Link component={RouterLink} to={`/${id}/settings`}>
                      <div className="rounded-full bg-gray-400 text-white p-2">
                        <AddIcon />
                      </div>
                      <div className="mt-1 text-xs text-center truncate">
                        {t("add-link")}
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </Container>
          ) : (
            <Container className="px-6 text-center text-gray-400">
              {t("no-social-media-links")}
            </Container>
          )}
        </div>
      </MainLayout>
    </>
  );
};

export default CardPage;
