import React from "react";
import Logo from "./Logo";

const MainLoader = () => (
  <div className="fixed inset-0 flex items-center">
    <Logo className="mx-auto blink w-32 animate-pulse" />
  </div>
);

export default MainLoader;
