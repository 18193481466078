import React from "react";
import clsx from "clsx";

const Alert = ({ className, variant, ...rest }) => (
  <div
    className={clsx(
      "p-2 bg-gray-50 border border-gray-200 text-gray-600",
      variant === "danger" && "bg-red-50 border-red-200 text-red-600"
    )}
    {...rest}
  />
);

export default Alert;
