import React from "react";

const Sad = () => (
  <div class="emoji  emoji--sad">
    <div class="emoji__face">
      <div class="emoji__eyebrows"></div>
      <div class="emoji__eyes"></div>
      <div class="emoji__mouth"></div>
    </div>
  </div>
);

export default Sad;
