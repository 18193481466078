import {FieldArray, Form, Formik, useFormikContext} from "formik";
import React, {useEffect, useMemo} from "react";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {Redirect, useParams} from "react-router-dom";
import defaultsDeep from "lodash/defaultsDeep";
import * as yup from "yup";
import Container from "../components/Container";
import Input from "../components/Input";
import InputGroupList from "../components/InputGroupList";
import Label from "../components/Label";
import MainLoader from "../components/MainLoader";
import Textarea from "../components/Textarea";
import useCardQuery from "../hooks/useCardQuery";
import useCardsQuery from "../hooks/useCardsQuery";
import useLinksQuery from "../hooks/useLinksQuery";
import MainLayout from "../layouts/MainLayout";
import Button from "../components/Button";
import ProfilePicture from "../components/ProfilePicture";
import {useRepo} from "../hooks/repo";
import {useMutation} from "react-query";
import Alert from "../components/Alert";
import ContactInput from "../components/ContactInput";
import SocialMediaInput from "../components/SocailMediaInput";

const emptyStringToNull = (value) => (value === "" ? null : value);
const phoneRegExp = /^\s*\+?\s*([0-9][\s-]*){9,}$/;

const Section = ({title, description, children}) => (
    <section className="py-8">
        {title && <h2 className="text-lg">{title}</h2>}
        {description && <p className="text-gray-500">{description}</p>}
        <div className="mt-4">{children}</div>
    </section>
);

const DetailsSection = () => {
    const {t} = useTranslation();
    const {isSubmitting} = useFormikContext();
    return (
        <Section
            title={t("profile-details")}
            description={t("profile-details-description")}
        >
            <InputGroupList>
                <div>
                    <Label htmlFor="name">{t("name")}</Label>
                    <Input
                        id="name"
                        placeholder={t("name-placeholder")}
                        name="Nickname"
                        dir="auto"
                        disabled={isSubmitting}
                        block
                    />
                </div>
                <div>
                    <Label htmlFor="bio">{t("bio")}</Label>
                    <Textarea
                        id="bio"
                        placeholder={t("bio-placeholder")}
                        name="Bio"
                        rows={2}
                        dir="auto"
                        disabled={isSubmitting}
                        block
                    />
                </div>
            </InputGroupList>
        </Section>
    );
};

const PhonesSection = () => {
    const {t} = useTranslation();
    const {values, isSubmitting} = useFormikContext();
    return (
        <FieldArray name="phones">
            {({remove, push}) => (
                <Section
                    title={t("phone-numbers")}
                    description={t("phone-numbers-description")}
                >
                    <div className="space-y-4">
                        {values.phones.length > 0 &&
                            values.phones.map((_, index) => (
                                <div key={`phone-${index}`}>
                                    <ContactInput
                                        name={`phones.${index}.Value`}
                                        buttonProps={{onClick: () => remove(index)}}
                                        inputProps={{placeholder: "+971 50 123 4567", dir: "ltr"}}
                                    />
                                </div>
                            ))}
                        <Button
                            onClick={() => push({Value: ""})}
                            block
                            disabled={isSubmitting}
                            type="button"
                        >
                            {t("add-phone-number")}
                        </Button>
                    </div>
                </Section>
            )}
        </FieldArray>
    );
};

const EmailsSection = () => {
    const {t} = useTranslation();
    const {values, isSubmitting} = useFormikContext();
    return (
        <FieldArray name="emails">
            {({remove, push}) => (
                <Section
                    title={t("email-addresses")}
                    description={t("email-addresses-description")}
                >
                    <div className="space-y-4">
                        {values.emails.length > 0 &&
                            values.emails.map((_, index) => (
                                <div key={`email-${index}`}>
                                    <ContactInput
                                        name={`emails.${index}.Value`}
                                        buttonProps={{onClick: () => remove(index)}}
                                        inputProps={{placeholder: "user@example.com", dir: "ltr"}}
                                    />
                                </div>
                            ))}
                        <Button
                            type="button"
                            onClick={() => push({Value: ""})}
                            disabled={isSubmitting}
                            block
                        >
                            {t("add-email-address")}
                        </Button>
                    </div>
                </Section>
            )}
        </FieldArray>
    );
};

const SocialLinksSection = ({card}) => {
    const {t} = useTranslation();
    const {data: links} = useLinksQuery();
    const {values, isSubmitting} = useFormikContext();

    if (!links) {
        return null;
    }

    const filteredLinks = links.filter(link => !link.PartnerId || link.PartnerId === card.Partner?.Id);
//    const filteredLinks = links.filter(link => !link.Hidden && (!link.PartnerId || link.PartnerId === card.Partner?.Id));

    return (
        <FieldArray name="links">
            {({remove, push}) => (
                <Section title={t("links")} description={t("links-description")}>
                    <div className="space-y-4">
                        {values.links.length > 0 &&
                            values.links.map((value, index) => (
                                <SocialMediaInput
                                    key={`links-${index}`}
                                    links={filteredLinks}
                                    name={`links.${index}`}
                                    buttonProps={{onClick: () => remove(index)}}
                                />
                            ))}
                        <Button
                            type="button"
                            onClick={() => push({LinkId: filteredLinks[0].Id, Value: ""})}
                            disabled={isSubmitting}
                            block
                        >
                            {t("add-link")}
                        </Button>
                    </div>
                </Section>
            )}
        </FieldArray>
    );
};

const PictureInput = (props) => {
    const {t} = useTranslation();
    const {repo} = useRepo();
    const {
        isLoading,
        isError,
        isSuccess,
        mutate,
        data,
        error,
    } = useMutation((data) => repo.mutateMedia(data));
    const {setFieldValue, setFieldTouched, getFieldMeta} = useFormikContext();
    const {value} = getFieldMeta(props.name);

    const onChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.addEventListener("load", (e) => mutate({media: e.target.result}));
        reader.readAsArrayBuffer(file);
    };

    useEffect(() => {
        if (isSuccess) {
            setFieldTouched(props.name, true);
            setFieldValue(props.name, data);
        }
    }, [data, isSuccess, props.name, setFieldTouched, setFieldValue]);

    useEffect(() => {
        if (isError) {
            alert(t(error.message));
        }
    }, [isError, error, t]);

    const removePicture = () => {
        setFieldTouched(props.name, true);
        setFieldValue(props.name, null);
    };

    return (
        <>
            <ProfilePicture className="mx-auto w-32" image={value}/>
            <div className="flex justify-center space-x-4 mt-4">
                <Button
                    type="button"
                    component="label"
                    disabled={isLoading}
                    loading={isLoading && t("uploading-picture")}
                >
                    {t("upload-picture")}
                    <input
                        type="file"
                        accept="image/jpeg,image/webp,image/png,image/svg+xml"
                        onChange={onChange}
                        disabled={isLoading}
                        className="hidden"
                    />
                </Button>
                {value && !isLoading && (
                    <Button type="button" onClick={removePicture}>
                        {t("remove-picture")}
                    </Button>
                )}
            </div>
        </>
    );
};

const defaultValues = {
    Nickname: "",
    Bio: "",
    Picture: null,
    phones: [],
    emails: [],
    SiteUrl: "",
};

const CardSettingsPage = () => {
    const {repo} = useRepo();
    const {id} = useParams();
    const {t} = useTranslation();
    const {isError, error, mutateAsync, isSuccess} = useMutation((input) => {
        const {Nickname, Bio, phones, emails, links, Picture, ...rest} = schema.cast(
            input
        );
        return repo.mutateCard({
            id,
            data: {
                Nickname,
                Bio,
                picture: Picture?.Filename,
                contacts: [
                    ...phones.map(({Type, Value}) => ({
                        ContactType: "phone",
                        Type,
                        Value,
                    })),
                    ...emails.map(({Type, Value}) => ({
                        ContactType: "email",
                        Type,
                        Value,
                    })),
                ],
                links,
                ...rest,
            },
        });
    });

    const cardQuery = useCardQuery(id);
    const cardsQuery = useCardsQuery();

    const schema = useMemo(() => {
        const str = yup
            .string()
            .nullable()
            .transform(emptyStringToNull)
            .min(3)
            .max(255);

        return yup.object().shape({
            Nickname: str.clone().label(t("name")),
            Bio: str.clone().label(t("bio")),
            Picture: yup.object().nullable(),
            phones: yup.array().of(
                yup.object().shape({
                    Value: str
                        .clone()
                        .required()
                        .matches(phoneRegExp, t("invalid-phone"))
                        .label(t("phone")),
                })
            ),
            emails: yup.array().of(
                yup.object().shape({
                    Value: str.clone().required().email().lowercase().label(t("email")),
                })
            ),
            links: yup.array().of(
                yup.object().shape({
                    LinkId: yup.number(),
                })
            ),
            SiteUrl: str.clone().matches(/^([a-z][a-z0-9+\-.]*:(\/\/([a-z0-9\-._~%!$&'()*+,;=]+@)?([a-z0-9\-._~%]+|\[[a-f0-9:.]+]|\[v[a-f0-9][a-z0-9\-._~%!$&'()*+,;=:]+])(:[0-9]+)?(\/[a-z0-9\-._~%!$&'()*+,;=:@]+)*\/?|(\/?[a-z0-9\-._~%!$&'()*+,;=:@]+(\/[a-z0-9\-._~%!$&'()*+,;=:@]+)*\/?)?)|([a-z0-9\-._~%!$&'()*+,;=@]+(\/[a-z0-9\-._~%!$&'()*+,;=:@]+)*\/?|(\/[a-z0-9\-._~%!$&'()*+,;=:@]+)+\/?))(\?[a-z0-9\-._~%!$&'()*+,;=:@/?]*)?(#[a-z0-9\-._~%!$&'()*+,;=:@/?]*)?$/i, (props) => t("yup-string-url", props)).label(t("site-url")),
        });
    }, [t]);

    if (cardQuery.isLoading || cardsQuery.isLoading) {
        return <MainLoader/>;
    }

    if (cardQuery.isError || cardsQuery.isError) {
        // TODO: Error Page
        return "error occurred";
    }

    const card = cardQuery.data;
    const cards = cardsQuery.data;
    const isOwner = Boolean(cards && cards.find((item) => item.Id === card.Id));

    if (!isOwner || isSuccess) {
        return <Redirect to={`/${id}`}/>;
    }


    const inputs = {
        Picture: card.Picture,
        Nickname: card.Nickname,
        Bio: card.Bio,
        SiteUrl: card.SiteUrl,
        phones:
            card.Contacts?.filter((contact) => contact.ContactType === "phone") || [],
        emails:
            card.Contacts?.filter((contact) => contact.ContactType === "email") || [],
        links:
            card.Links?.filter(({Link}) => !Link.Hidden).map(({Link, Value}) => ({
                LinkId: Link.Id,
                Value,
            })) || [],
    };

    const initialValues = defaultsDeep(inputs, defaultValues);

    return (
        <>
            <Helmet>
                <title>{t("manage-profile")}</title>
            </Helmet>
            <MainLayout>
                <Container className="py-8 px-4">
                    <div className="text-center">
                        <h1 className="text-xl">{t("manage-profile")}</h1>
                        <p className="text-gray-500">{t("manage-profile-description")}</p>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={mutateAsync}
                    >
                        {({isSubmitting}) => (
                            <Form noValidate>
                                <div className="divide-y divide-gray-300 divide-solid">
                                    <Section>
                                        <PictureInput name="Picture"/>
                                    </Section>
                                    <DetailsSection />
                                    <Section
                                        title={t("site-url")}
                                        description={t("site-url-description")}
                                    >
                                        <Input
                                            placeholder="https://example.com"
                                            name="SiteUrl"
                                            dir="auto"
                                            disabled={isSubmitting}
                                            block
                                        />
                                    </Section>
                                    <PhonesSection/>
                                    <EmailsSection/>
                                    <SocialLinksSection card={card}/>
                                </div>
                                {isError && <Alert variant="danger">{t(error.message)}</Alert>}
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    block
                                    loading={isSubmitting && t("saving-profile")}
                                >
                                    {t("save-profile")}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Container>
            </MainLayout>
        </>
    );
};

export default CardSettingsPage;
