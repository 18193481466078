import React, {useEffect} from "react";
import Logo from "../components/Logo";
import Main from "../components/Main";
import Wrapper from "../components/Wrapper";
import Container from "../components/Container";
import Footer from "../components/Footer";
import {useAuth} from "../hooks/auth";
import {useHistory} from "react-router-dom";

const AuthLayout = ({title, description, children, ...rest}) => {
    const {isLoggedIn} = useAuth();
    const history = useHistory();

    useEffect(() => {
        if (isLoggedIn) {
            const cardToActivate = localStorage.getItem("card-to-activate");
            if (cardToActivate) {
                localStorage.removeItem("card-to-activate");
                history.push(`/${cardToActivate}`);
            } else {
                history.push(`/cards`);
            }
        }
    }, [isLoggedIn, history]);

    return (
        <Wrapper>
            <Main className="py-12" {...rest}>
                <Logo className="w-40 mx-auto"/>
                <Container maxWidth="xs" className="mt-12 px-4">
                    {(title || description) && (
                        <div className="mb-4">
                            {title && <h1 className="font-bold text-xl text-center">{title}</h1>}
                            {description && (
                                <p className="text-gray-600 text-center mt-2">{description}</p>
                            )}
                        </div>
                    )}
                    {children}
                </Container>
            </Main>
            <Footer/>
        </Wrapper>
    );
};

export default AuthLayout;
