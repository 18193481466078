import { createContext, useContext, useState } from "react";
import axios from "axios";
import Repo from "../lib/repo";
import isNil from "lodash/isNil";

const RepoContext = createContext();

export const RepoProvider = ({ children, i18next }) => {
  const [authToken, setToken] = useState(localStorage.getItem("token"));
  const setAuthToken = (token) => {
    if (isNil(token)) {
      localStorage.removeItem("token");
      setToken();
    } else {
      localStorage.setItem("token", token);
      setToken(token);
    }
  };

  const instance = axios.create({
    headers: {
      "Accept-Language": i18next.language,
      Authorization: authToken && `Bearer ${authToken}`,
    },
  });

  const repo = new Repo({ axios: instance, i18next });

  return (
    <RepoContext.Provider value={{ repo, authToken, setAuthToken }}>
      {children}
    </RepoContext.Provider>
  );
};

export const useRepo = () => useContext(RepoContext);
