import clsx from "clsx";
import React, { useState } from "react";

const Image = ({ image, className, alt, ...reset }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <div>
      <img
        src={image.Base64}
        alt={alt}
        aria-hidden
        className={clsx(loaded && "hidden", className)}
        {...reset}
      />
      <img
        src={image.Base64}
        srcSet={Object.keys(image.SrcSet)
          .map((key) => `${image.SrcSet[key]} ${key}`)
          .join(", ")}
        alt={alt}
        onLoad={() => setLoaded(true)}
        className={clsx(!loaded && "hidden", className)}
        {...reset}
      />
    </div>
  );
};

export default Image;
