import React, { useEffect, useRef } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

const FormikI18n = (props) => {
  const { i18n } = useTranslation();
  const formik = useRef(null);
  useEffect(() => {
    if (formik.current) {
      formik.current.validateForm();
    }
  }, [i18n.language]);
  return <Formik innerRef={formik} {...props} />;
};

export default FormikI18n;
