import { useEffect } from "react";

const HomePage = () => {
  useEffect(() => {
    window.location = "https://qicky.shop";
  }, []);

  return null;
};

export default HomePage;
