import React from "react";

const Wow = () => (
  <div className="emoji  emoji--wow">
    <div className="emoji__face">
      <div className="emoji__eyebrows"></div>
      <div className="emoji__eyes"></div>
      <div className="emoji__mouth"></div>
    </div>
  </div>
);

export default Wow;
