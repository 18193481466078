import React from "react";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";

const Link = ({ component: Component = RouterLink, className, ...rest }) => (
  <Component
    className={clsx("hover:underline focus:underline outline:none", className)}
    {...rest}
  />
);

export default Link;
