import React from "react";

const Haha = () => (
  <div className="emoji  emoji--haha">
    <div className="emoji__face">
      <div className="emoji__eyes"></div>
      <div className="emoji__mouth">
        <div className="emoji__tongue"></div>
      </div>
    </div>
  </div>
);

export default Haha;
