import React from "react";

const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2596.2 988.8"
    fill="currentColor"
    strokeWidth="19"
    strokeMiterlimit="10"
    stroke="currentColor"
    {...props}
  >
    <path
      d="M524,315.5c29.8,49.8,47.8,98.7,53.7,146.8c0,345.3,0,517.6,0,517H508V674.7c-29.5,36.2-62.5,63.1-99,80.9
		c-36.5,17.7-76.4,26.6-119.9,26.6c-77,0-142.8-28-197.5-84.1C36.9,642,9.5,574,9.5,494.1c0-78.6,27.5-145.8,82.6-201.5
		s121.4-83.6,199-83.6c44.4,0,86.2,8.5,125.1,25.6C455.2,251.7,491.1,278.7,524,315.5z M297.1,276.2c-39.5,0-75.7,9.7-108.7,29.1
		c-33,19.4-59.3,46.4-78.9,80.9s-29.4,71.3-29.4,110.5c0,38.5,9.9,75.4,29.6,110.7s46.3,62.7,79.6,82.1
		c33.3,19.4,68.9,29.1,106.7,29.1c38.5,0,75-9.7,109.5-29.1s61-45.8,79.6-79.1S513,539.8,513,498.7c0-63.3-20.9-116.2-62.7-158.7
		C408.5,297.4,357.5,276.2,297.1,276.2z"
    />
    <path
      d="M743.2,9.5c15.9,0,29.6,5.6,41,16.9s17.2,24.9,17.2,40.8s-5.7,29.6-17.2,41s-25.1,17.2-41,17.2
		s-29.5-5.7-40.8-17.2s-16.9-25.1-16.9-41s5.6-29.5,16.9-40.8S727.2,9.5,743.2,9.5z M708.3,233.9h70.2v545.8h-70.2V233.9z"
    />
    <path
      d="M1453.2,334.4l-55.7,34.8c-48.1-64.3-113.8-96.5-197-96.5c-66.7,0-122,21.4-166,64.2
		c-44,42.8-65.9,94.9-65.9,156.2c0,39.8,10.1,77.4,30.3,112.7s47.9,62.6,83.1,81.8c35.2,19.2,74.6,28.9,118.4,28.9
		c80.6,0,146.3-31.8,197-95.5l55.7,36.3c-26.2,39.2-61.3,69.4-105.2,90.8c-44,21.4-94.1,32.1-150.5,32.1
		c-85.9,0-157.3-27.4-214.2-82.1c-56.9-54.7-85.3-121.2-85.3-199.5c0-53.1,13.3-102.2,40-147.5s63.2-80.6,109.5-106
		c46.3-25.4,98.3-38.1,156-38.1c35.8,0,70.6,5.5,104.2,16.4c33.7,10.9,62.3,25.3,85.8,43C1417.1,284.2,1437,306.8,1453.2,334.4z"
    />
    <path
      d="M1586.8,22.9h70.7v430.9l252.2-219.9h103.5l-228.4,207.5l245.8,338.3h-99.5l-215-293.6l-58.7,48.3v245.3h-70.7
		V22.9H1586.8z"
    />
    <path d="M2062.8,220.9h74.1l187.6,420.5L2506.6,221h75.1l-325.9,744.8h-74.1l105-240.3L2062.8,220.9z" />
  </svg>
);

export default Logo;
