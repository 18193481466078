import React from "react";
import clsx from "clsx";
import Loader from "./Loader";

const Button = ({
  component: Component = "button",
  block = false,
  loading,
  className,
  children,
  variant = "primary",
  ...rest
}) => (
  <Component
    className={clsx(
      "appearance-none text-white text-center rounded p-3 cursor-pointer hover:no-underline focus:no-underline hover:bg-opacity-80 focus:outline-none focus:ring disabled:bg-opacity-100 disabled:opacity-60 disabled:cursor-auto",
      { "block w-full": block },
      {
        "bg-black focus:ring-gray-300": variant === "primary",
        "bg-red-500 focus:ring-red-200": variant === "danger",
        "bg-green-500 focus:ring-green-200": variant === "success",
      },
      className
    )}
    {...rest}
  >
    {loading ? (
      <div className="flex items-center justify-center">
        <Loader className="animate-spin w-5 h-5" />
        <div className="ml-2">{loading}</div>
      </div>
    ) : (
      children
    )}
  </Component>
);

export default Button;
