import clsx from "clsx";
import React from "react";
import BaseInput from "./BaseInput";
import ChevronDownIcon from "./icons/ChevronDownIcon";

const Select = ({ className, ...rest }) => (
  <div className="relative">
    <BaseInput
      component="select"
      className={clsx("appearance-none", className)}
      block
      {...rest}
    />
    <div className="absolute right-2 inset-y-0 flex items-center">
      <ChevronDownIcon className="w-6" />
    </div>
  </div>
);

export default Select;
