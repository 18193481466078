import Axios from "axios"

class Repo {
  constructor({ axios, i18next }) {
    this.axios = axios;
    this.i18next = i18next;
  }

  async queryCardById(id) {
    const { data } = await this.axios.get(`/api/card/${id}`);
    return data;
  }

  async queryProfile() {
    const { data } = await this.axios.get("/api/auth/profile");
    return data;
  }

  async queryCards() {
    const { data } = await this.axios.get("/api/card");
    return data;
  }

  async queryLinks() {
    const { data } = await this.axios.get("/api/link");
    return data;
  }

  async mutateLogin({ email, password }) {
    try {
      const { data } = await this.axios.post("/api/auth/login", {
        email,
        password,
      });
      return data;
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 404:
            throw new Error("invalid-email");
          case 401:
            throw new Error("invalid-password");
          default:
            throw new Error("unkown-error");
        }
      }
      throw new Error("network-error");
    }
  }

  async mutateRegister({ email, password }) {
    try {
      const { data } = await this.axios.post("/api/auth/register", {
        email,
        password,
      });
      return data;
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 409:
            throw new Error("email-already-registered");
          default:
            throw new Error("unkown-error");
        }
      }
      throw new Error("network-error");
    }
  }

  async mutateResetPasswordRequest(email) {
    try {
      await this.axios.post("/api/auth/reset-password", { email });
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 404:
            throw new Error("email-is-not-registered");
          default:
            throw new Error("unkown-error");
        }
      }
      throw new Error("network-error");
    }
  }

  async mutateResetPassword({ token, password }) {
    try {
      await this.axios.put("/api/auth/reset-password", { token, password });
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          // TODO: check posible errors
          default:
            throw new Error("unkown-error");
        }
      }
      throw new Error("network-error");
    }
  }

  async mutateActivateCard({ id }) {
    try {
      await this.axios.put(`/api/card/${id}`);
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          // TODO: check posible errors
          default:
            throw new Error("unkown-error");
        }
      }
      throw new Error("network-error");
    }
  }

  async mutateMedia({ media }) {
    try {
      const { data } = await this.axios.post("/api/media/card", media);
      return data;
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          // TODO: check posible errors
          default:
            throw new Error("unkown-error");
        }
      }
      throw new Error("network-error");
    }
  }

  async mutateCard({ id, data }) {
    try {
      const res = await this.axios.post(`/api/card/${id}`, data);
      return res.data;
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          // TODO: check posible errors
          default:
            throw new Error("unkown-error");
        }
      }
      throw new Error("network-error");
    }
  }

  async queryOrder(token) {
    try {
      const res = await this.axios.get(`/api/order/${token}`);
      return res.data;
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          // TODO: check posible errors
          default:
            throw new Error("unkown-error");
        }
      }
      throw new Error("network-error");
    }
  }

  async url2base64(url) {
    try {
      const { data } = await Axios.get(url, {
        responseType: "arraybuffer",
      });
      return Buffer.from(data, "binary").toString("base64");
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          // TODO: check posible errors
          default:
            throw new Error("unkown-error");
        }
      }
      throw new Error("network-error");
    }
  }
}

export default Repo;
