import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Form } from "formik";
import * as yup from "yup";
import Button from "../components/Button";
import Input from "../components/Input";
import InputGroupList from "../components/InputGroupList";
import Label from "../components/Label";
import Link from "../components/Link";
import FormikI18n from "../components/FormikI18n";
import AuthLayout from "../layouts/AuthLayout";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useRepo } from "../hooks/repo";
import Alert from "../components/Alert";

const ResetPasswordPage = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const { repo } = useRepo();
  const {
    isError,
    error,
    mutateAsync,
    isSuccess,
  } = useMutation(({ password }) =>
    repo.mutateResetPassword({ password, token })
  );

  const schema = useMemo(
    () =>
      yup.object().shape({
        password: yup
          .string()
          .required()
          .min(8)
          .max(72)
          .label(t("new-password")),
        confirmPassword: yup
          .string()
          .required()
          .oneOf([yup.ref("password"), null], t("passowrds-must-match"))
          .label(t("confirm-new-password")),
      }),
    [t]
  );

  const submitHandler = (data) => mutateAsync(schema.cast(data));

  if (isSuccess) {
    return (
      <>
        <Helmet>
          <title>{t("reset-password-success")}</title>
        </Helmet>
        <AuthLayout
          title={t("reset-password-success")}
          description={t("reset-password-success-description")}
        >
          <Button component={Link} block to="/login" className="mt-4">
            {t("login")}
          </Button>
        </AuthLayout>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t("reset-password")}</title>
      </Helmet>
      <AuthLayout
        title={t("reset-password")}
        description={t("password-reset-description")}
      >
        <FormikI18n
          initialValues={{ password: "", confirmPassword: "" }}
          onSubmit={submitHandler}
          validationSchema={schema}
        >
          {({ isSubmitting }) => (
            <InputGroupList component={Form} noValidate>
              <div>
                <Label htmlFor="password-input">{t("new-password")}</Label>
                <Input
                  id="password-input"
                  name="password"
                  type="password"
                  dir="auto"
                  disabled={isSubmitting}
                  block
                />
              </div>
              <div>
                <Label htmlFor="confirm-password-input">
                  {t("confirm-new-password")}
                </Label>
                <Input
                  id="confirm-password-input"
                  name="confirmPassword"
                  type="password"
                  dir="auto"
                  disabled={isSubmitting}
                  block
                />
              </div>
              {isError && <Alert variant="danger">{t(error.message)}</Alert>}
              <Button
                disabled={isSubmitting}
                loading={isSubmitting && t("reseting-password")}
                block
                type="submit"
              >
                {t("reset-password")}
              </Button>
            </InputGroupList>
          )}
        </FormikI18n>
      </AuthLayout>
    </>
  );
};

export default ResetPasswordPage;
