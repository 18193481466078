import React from "react";

const Yay = () => (
  <div className="emoji  emoji--yay">
    <div className="emoji__face">
      <div className="emoji__eyebrows"></div>
      <div className="emoji__mouth"></div>
    </div>
  </div>
);

export default Yay;
