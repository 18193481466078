import React from "react";

const EmailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 330.001 330.001"
    fill="currentColor"
    {...props}
  >
    <path
      d="M165.001,0c-90.981,0-165,74.018-165,164.997c0,90.983,74.019,165.004,165,165.004c8.284,0,15-6.716,15-15
	c0-8.284-6.716-15-15-15c-74.44,0-135-60.563-135-135.004C30,90.56,90.561,30,165.001,30s135,60.56,135,134.997v37.507
	c0,12.407-10.096,22.5-22.504,22.5c-12.406,0-22.499-10.093-22.499-22.5v-37.507c0-49.626-40.372-90-89.997-90
	c-49.626,0-89.999,40.374-89.999,90c0,49.63,40.373,90.007,89.999,90.007c25.927,0,49.323-11.026,65.762-28.63
	c8.706,16.978,26.379,28.63,46.734,28.63c28.951,0,52.504-23.551,52.504-52.5v-37.507C330,74.018,255.982,0,165.001,0z
	 M165.001,225.004c-33.084,0-59.999-26.919-59.999-60.007c0-33.084,26.915-60,59.999-60c33.083,0,59.997,26.916,59.997,60
	C224.998,198.085,198.083,225.004,165.001,225.004z"
    />
  </svg>
);
export default EmailIcon;
