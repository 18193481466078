import clsx from "clsx";
import React from "react";
import Container from "../../components/Container";
import MenuIcon from "../../components/icons/MenuIcon";
import Logo from "../../components/Logo";
import { useMenu } from "../../hooks/menu";

const Navbar = ({ className, ...rest }) => {
  const { setMenuOpen } = useMenu();
  const openMenu = () => setMenuOpen(true);
  return (
    <div className={clsx("border-b", className)} {...rest}>
      <Container className="px-4 h-12 flex items-center justify-between">
        <a className="text-2xl" href="/" target="_blank" rel="noreferrer">
          <Logo className="h-6" />
        </a>
        <button onClick={openMenu}>
          <MenuIcon className="h-10" />
        </button>
      </Container>
    </div>
  );
};

export default Navbar;
