import clsx from "clsx";
import React from "react";
import {useTranslation} from "react-i18next";
import {
    Link as RouterLink,
    NavLink,
    useHistory,
    useParams,
} from "react-router-dom";
import LoginIcon from "../../components/icons/LoginIcon";
import LogoutIcon from "../../components/icons/LogoutIcon";
import GetCardIcon from "../../components/icons/GetCardIcon";
import ProfilePicture from "../../components/ProfilePicture";
import {useAuth} from "../../hooks/auth";
import {useRepo} from "../../hooks/repo";
import useCardsQuery from "../../hooks/useCardsQuery";
import useCardQuery from "../../hooks/useCardQuery";
import MyProfileIcon from "../../components/icons/MyProfileIcon";
import SettingsIcon from "../../components/icons/SettingsIcon";

const MenuList = ({className, ...rest}) => (
    <div className={clsx("flex flex-col", className)} {...rest} />
);

const MenuListCaption = ({className, ...rest}) => (
    <div
        className={clsx("text-sm text-gray-600 text-left ml-3 mb-2", className)}
        {...rest}
    />
);

const MenuListItem = ({component: Component = "div", className, ...rest}) => (
    <Component
        className={clsx(
            "p-2 hover:bg-gray-100 flex items-center space-x-4",
            className
        )}
        {...rest}
    />
);

const MenuListItemIcon = ({component: Component, className, ...rest}) => (
    <Component className={clsx("w-6 h-6 text-gray-600", className)} {...rest} />
);

const MenuListItemText = ({className, ...rest}) => (
    <span className={clsx("", className)} {...rest} />
);

const Menu = ({closeMenu, className, ...rest}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const {id} = useParams();
    const {setAuthToken} = useRepo();
    const {isLoggedIn} = useAuth();

    const {data: card} = useCardQuery(id, {enabled: Boolean(id)});
    const {data: cards} = useCardsQuery();

    const isOwner = Boolean(cards?.find((item) => item.Id === card?.Id));
    const otherCards = cards?.filter((item) => item.Id !== card?.Id);

    const logout = () => {
        if (window.confirm(t("logout-confirmation"))) {
            setAuthToken(null);
        }
        history.push("/login");
    };

    const getCardsMenuList = () => {
        if (isOwner) {
            if (otherCards.length > 0) {
                return (
                    <MenuList>
                        <MenuListCaption>{t("other-cards")}</MenuListCaption>
                        {otherCards.map((card) => (
                            <MenuListItem
                                key={`menu-list-other-cards-${card.Id}`}
                                component={RouterLink}
                                to={`/${card.Id}`}
                                onClick={closeMenu}
                            >
                                <ProfilePicture image={card.Avatar} className="w-8 h-8"/>
                                <MenuListItemText>
                                    {card.Nickname || t("unnamed")}
                                </MenuListItemText>
                            </MenuListItem>
                        ))}
                    </MenuList>
                );
            }
            return null;
        }

        if (cards) {
            return (
                <MenuList>
                    <MenuListCaption>{t("my-cards")}</MenuListCaption>
                    {cards.map((card) => (
                        <MenuListItem
                            key={`menu-list-cards-${card.Id}`}
                            component={RouterLink}
                            to={`/${card.Id}`}
                            onClick={closeMenu}
                        >
                            <ProfilePicture image={card.Avatar} className="w-8 h-8"/>
                            <MenuListItemText>
                                {card.Nickname || t("unnamed")}
                            </MenuListItemText>
                        </MenuListItem>
                    ))}
                </MenuList>
            );
        }
    };

    return (
        <aside
            className={clsx(
                "fixed inset-y-0 right-0 w-64 shadow-2xl bg-white overflow-y-auto space-y-6 py-8 z-10",
                className
            )}
            {...rest}
        >
            {isOwner && (
                <>
                    <header className="flex flex-col items-center">
                        <ProfilePicture className="w-24 h-24" image={card.Avatar}/>
                        <h1 className="mt-3">
                            {card.Nickname || t("unnamed")}
                        </h1>
                    </header>
                    <MenuList>
                        <MenuListItem
                            component={NavLink}
                            to={`/${id}`}
                            onClick={closeMenu}
                            activeClassName="bg-gray-200"
                            exact
                        >
                            <MenuListItemIcon component={MyProfileIcon}/>
                            <MenuListItemText>{t("my-profile")}</MenuListItemText>
                        </MenuListItem>
                        <MenuListItem
                            component={NavLink}
                            to={`/${id}/settings`}
                            onClick={closeMenu}
                            activeClassName="bg-gray-200"
                            exact
                        >
                            <MenuListItemIcon component={SettingsIcon}/>
                            <MenuListItemText>{t("manage-profile")}</MenuListItemText>
                        </MenuListItem>
                    </MenuList>
                </>
            )}
            {getCardsMenuList()}
            <MenuList>
                <MenuListCaption>{t("options")}</MenuListCaption>
                <MenuListItem
                    component="a"
                    href="/"
                    target="_blank"
                    rel="noreferrer"
                    onClick={closeMenu}
                >
                    <MenuListItemIcon component={GetCardIcon}/>
                    <MenuListItemText>{t("get-card")}</MenuListItemText>
                </MenuListItem>
                {isLoggedIn && (
                    <MenuListItem
                        component="button"
                        onClick={logout}
                        className="flex items-center"
                    >
                        <MenuListItemIcon component={LogoutIcon}/>
                        <MenuListItemText>{t("logout")}</MenuListItemText>
                    </MenuListItem>
                )}
                {!isLoggedIn && (
                    <MenuListItem
                        component={RouterLink}
                        to="/login"
                        onClick={closeMenu}
                        className="flex items-center"
                    >
                        <MenuListItemIcon component={LoginIcon}/>
                        <MenuListItemText>{t("login")}</MenuListItemText>
                    </MenuListItem>
                )}
            </MenuList>
        </aside>
    );
};

export default Menu;
