import React from "react";
import clsx from "clsx";

const widths = {
  xs: "max-w-xs",
  sm: "max-w-sm",
  md: "max-w-md",
  lg: "max-w-lg",
  xl: "max-w-xl",
  "2xl": "max-w-2xl",
  "3xl": "max-w-3xl",
  "4xl": "max-w-4xl",
  "5xl": "max-w-5xl",
  "6xl": "max-w-6xl",
  "7xl": "max-w-7xl",
};

const Container = ({
  component: Component = "div",
  maxWidth = "md",
  className,
  ...rest
}) => (
  <Component
    className={clsx(`w-full mx-auto`, widths[maxWidth], className)}
    {...rest}
  />
);

export default Container;
