import React, { forwardRef } from "react";
import clsx from "clsx";

const Wrapper = forwardRef(
  ({ component: Component = "div", className, ...rest }, ref) => (
    <Component
      className={clsx(
        "flex flex-col min-h-screen relative inset-0 overflow-y-auto font-sans",
        className
      )}
      {...rest}
      ref={ref}
    />
  )
);

export default Wrapper;
