import {createContext, useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSwipeable} from "react-swipeable";
import {CSSTransition} from "react-transition-group";
import Backdrop from "../components/Backdrop";
import CloseIcon from "../components/icons/CloseIcon";
import Menu from "../layouts/MainLayout/Menu";
import ScrollLock from "react-scrolllock";

const MenuContext = createContext();

export const MenuProvider = ({children}) => {
    const {i18n} = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);
    const closeMenu = () => setMenuOpen(false);

    const handlers = useSwipeable({
        onSwiped: ({dir}) => {
            if (["Left", "Right"].includes(dir)) {
                if (i18n.dir() === "ltr") {
                    setMenuOpen(dir === "Left");
                } else {
                    setMenuOpen(dir === "Right");
                }
            }
        },
    });

    return (
        <div {...handlers}>
            <ScrollLock isActive={menuOpen}/>
            <CSSTransition
                in={menuOpen}
                classNames="fade"
                timeout={300}
            >
                <Backdrop className="backdrop" onClick={closeMenu}/>
            </CSSTransition>
            <CSSTransition
                in={menuOpen}
                classNames="slide"
                timeout={250}
            >
                <Menu className="aside-menu" closeMenu={closeMenu}/>
            </CSSTransition>
            <CSSTransition
                in={menuOpen}
                classNames="fade"
                timeout={300}
            >
                <button
                    className="menu-close-btn fixed left-2 top-2 z-10"
                    onClick={closeMenu}
                >
                    <CloseIcon className="h-10 text-white"/>
                </button>
            </CSSTransition>
            <MenuContext.Provider value={{menuOpen, setMenuOpen}}>
                {children}
            </MenuContext.Provider>
        </div>
    );
};

export const useMenu = () => useContext(MenuContext);
