import React from "react";
import { useField } from "formik";
import clsx from "clsx";

const BaseInput = ({
  component: Component = "input",
  block,
  className,
  ...rest
}) => {
  const [field, { error, touched }] = useField(rest);
  return (
    <>
      <Component
        className={clsx(
          "resize-none bg-white border py-2 px-3 border-gray-300 focus:outline-none focus:border-gray-400 disabled:bg-gray-100 disabled:opacity-60",
          { "block w-full": block },
          { "border-red-600 focus:border-red-700": error && touched },
          className
        )}
        {...field}
        {...rest}
      />
      {error && touched && <div className="text-red-600">{error}</div>}
    </>
  );
};

export default BaseInput;
