import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import Button from "../components/Button";
import Container from "../components/Container";
import Sad from "../components/emojis/Sad";
import MainLoader from "../components/MainLoader";
import useCardsQuery from "../hooks/useCardsQuery";
import MainLayout from "../layouts/MainLayout";

const CardsPage = () => {
  const { t } = useTranslation();
  const { isSuccess, isLoading, data } = useCardsQuery();

  if (isLoading) {
    return <MainLoader />;
  }

  if (isSuccess && data) {
    const [{ Id }] = data;
    return <Redirect to={`/${Id}`} />;
  }

  return (
    <>
      <Helmet>
        <title>{t("no-cards")}</title>
      </Helmet>
      <MainLayout>
        <Container className="px-2">
          <div className="text-center">
            <div className="text-8xl my-12">
              <Sad />
            </div>
            <h1 className="text-4xl">{t("no-cards")}</h1>
            <p className="text-gray-600 mt-2">{t("no-cards-description")}</p>
            <Button component="a" href="/" className="my-8 inline-block">
              {t("get-card")}
            </Button>
          </div>
        </Container>
      </MainLayout>
    </>
  );
};

export default CardsPage;
