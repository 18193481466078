import { useQuery } from "react-query";
import { useAuth } from "./auth";
import { useRepo } from "./repo";

const useCardsQuery = () => {
  const { profile, isLoggedIn } = useAuth();
  const { repo } = useRepo();
  return useQuery(["cards", profile?.Id], () => repo.queryCards(), {
    enabled: isLoggedIn,
  });
};

export default useCardsQuery;
