import React from "react";
import Footer from "../../components/Footer";
import Main from "../../components/Main";
import Wrapper from "../../components/Wrapper";
import Navbar from "./Navbar";

const MainLayout = ({ children }) => (
  <Wrapper>
    <Navbar />
    <Main>{children}</Main>
    <Footer />
  </Wrapper>
);

export default MainLayout;
