import React from "react";
import clsx from "clsx";

const InputGroupList = ({
  component: Component = "div",
  className,
  ...rest
}) => <Component className={clsx("space-y-4", className)} {...rest} />;

export default InputGroupList;
