import { createContext, useContext } from "react";
import { useQuery } from "react-query";
import MainLoader from "../components/MainLoader";
import { useRepo } from "./repo";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { repo, authToken } = useRepo();
  const { data, isError, isSuccess, isLoading } = useQuery(
    ["profile", authToken],
    () => repo.queryProfile(),
    {
      enabled: Boolean(authToken),
    }
  );

  if (isLoading) {
    return <MainLoader />;
  }

  if (isError) {
    // TODO: Error Page
    return "error occurred";
  }

  return (
    <AuthContext.Provider value={{ profile: data, isLoggedIn: isSuccess }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
