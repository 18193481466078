import React from "react";
import i18next from "i18next";
import * as yup from "yup";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import {initReactI18next, useTranslation} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import CardPage from "./pages/CardPage";
import LoginPage from "./pages/LoginPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import CreateAccountPage from "./pages/CreateAccountPage";
import ForgottenPasswordPage from "./pages/ForgottenPasswordPage";
import {RepoProvider} from "./hooks/repo";
import {AuthProvider, useAuth} from "./hooks/auth";
import CardSettingsPage from "./pages/CardSettingsPage";
import CardsPage from "./pages/CardsPage";
import NotFoundPage from "./pages/NotFoundPage";
import HomePage from "./pages/HomePage";
import acceptLanguage from "accept-language"
import {MenuProvider} from "./hooks/menu";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
        },
    },
});

acceptLanguage.languages(["ar", "en"]);
const languageDetector = new LanguageDetector();
languageDetector.addDetector({
    name: "my-navigator",
    lookup: () => acceptLanguage.get(window.navigator.languages.join(";")) || "en",
});

i18next
    .use(initReactI18next)
    .use(languageDetector)
    .init({
        supportedLngs: ["en", "ar"],
        fallbackLng: "en",
        defaultNS: "translation",
        saveMissing: true,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: require("./locales/en/translation.json")
            },
            ar: {
                translation: require("./locales/ar/translation.json")
            }
        },
        detection: {
            order: ["querystring", "localStorage", "my-navigator"],
            caches: ["localStorage"],
        },
    });

const App = () => {
    const {i18n, t} = useTranslation();

    yup.setLocale({
        mixed: {
            default: props => t("yup-mixed-default", props),
            required: props => t("yup-mixed-required", props),
            oneOf: props => t("yup-mixed-oneOf", props),
            notOneOf: props => t("yup-mixed-notOneOf", props),
            defined: props => t("yup-mixed-defined", props),
        },
        string: {
            length: props => t("yup-string-length", props),
            min: props => t("yup-string-min", props),
            max: props => t("yup-string-max", props),
            matches: props => t("yup-string-matches", props),
            email: props => t("yup-string-email", props),
            url: props => t("yup-string-url", props),
            uuid: props => t("yup-string-uuid", props),
            trim: props => t("yup-string-trim", props),
            lowercase: props => t("yup-string-lowercase", props),
            uppercase: props => t("yup-string-uppercase", props),
        },
        number: {
            min: props => t("yup-number-min", props),
            max: props => t("yup-number-max", props),
            lessThan: props => t("yup-number-lessThan", props),
            moreThan: props => t("yup-number-moreThan", props),
            positive: props => t("yup-number-positive", props),
            negative: props => t("yup-number-negative", props),
            integer: props => t("yup-number-integer", props),
        },
        date: {
            min: props => t("yup-date-min", props),
            max: props => t("yup-date-max", props),
        },
        object: {noUnknown: props => t("yup-object-noUnknown", props)},
        array: {
            min: props => t("yup-array-min", props),
            max: props => t("yup-array-max", props),
            length: props => t("yup-array-length", props),
        },
        boolean: {isValue: props => t("yup-boolean-isValue", props)},
    });

    return (
        <HelmetProvider>
            <RepoProvider i18next={i18n}>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                        <Helmet htmlAttributes={{dir: i18n.dir(), lang: i18n.language}}>
                            <title>{t("application-name")}</title>
                        </Helmet>
                        <BrowserRouter>
                            <Switch>
                                <Route path="/" exact>
                                    <HomePage/>
                                </Route>
                                <Route path="/login" exact>
                                    <LoginPage/>
                                </Route>
                                <Route path="/signup" exact>
                                    <CreateAccountPage/>
                                </Route>
                                <Route path="/reset-password" exact>
                                    <ForgottenPasswordPage/>
                                </Route>
                                <Route path="/reset-password/:token" exact>
                                    <ResetPasswordPage/>
                                </Route>
                                <CustomRoute path="/cards" exact requireAuth component={CardsPage}/>
                                <CustomRoute
                                    path="/:id([A-Za-z0-9]{6})"
                                    exact
                                    component={CardPage}
                                />
                                <CustomRoute
                                    path="/:id([A-Za-z0-9]{6})/settings"
                                    exact
                                    requireAuth
                                    component={CardSettingsPage}
                                />
                                <CustomRoute component={NotFoundPage}/>
                            </Switch>
                        </BrowserRouter>
                    </AuthProvider>
                </QueryClientProvider>
            </RepoProvider>
        </HelmetProvider>
    );
};

const CustomRoute = ({component: Component, requireAuth, ...rest}) => {
    const {isLoggedIn} = useAuth();
    return (
        <Route
            {...rest}
            render={(props) => {
                if (requireAuth && !isLoggedIn) {
                    return <Redirect to="/login"/>;
                }
                return (
                    <MenuProvider>
                        <Component {...props} />
                    </MenuProvider>
                );
            }}
        />
    )
};

export default App;
