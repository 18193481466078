import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Container from "./Container";
import ChevronDownIcon from "./icons/ChevronDownIcon";

const Footer = ({ component: Component = "footer", className, ...rest }) => {
  const { t, i18n } = useTranslation();

  const handleLanguageSelect = (e) => i18n.changeLanguage(e.target.value);

  return (
    <Component
      className={clsx("bg-black text-white bg py-2", className)}
      {...rest}
    >
      <Container className="px-4 flex justify-between items-center">
        <div className="relative">
          <select
            className="appearance-none bg-black text-white pr-4"
            onChange={handleLanguageSelect}
            value={i18n.language}
          >
            {["en", "ar"].map((lng) => (
              <option
                className="w-0 bg-white text-black"
                key={`language-option-${lng}`}
                value={lng}
              >
                {t("language", { lng })}
              </option>
            ))}
          </select>
          <div className="absolute right-0 inset-y-0 flex items-center">
            <ChevronDownIcon className="w-5" />
          </div>
        </div>
        <div className="text-sm">{t("rights")}</div>
      </Container>
    </Component>
  );
};

export default Footer;
