import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Container from "../components/Container";
import Haha from "../components/emojis/Haha";
import MainLayout from "../layouts/MainLayout";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("page-not-found")}</title>
      </Helmet>
      <MainLayout>
        <Container className="px-2">
          <div className="text-center">
            <div className="mt-10 mb-8">
              <Haha />
            </div>
            <h1 className="text-2xl">{t("page-not-found")}</h1>
            <p className="text-sm text-gray-600 mt-2">
              {t("page-not-found-description")}
            </p>
          </div>
        </Container>
      </MainLayout>
    </>
  );
};

export default NotFoundPage;
