import React from "react";
import { useField } from "formik";
import clsx from "clsx";
import CloseIcon from "./icons/CloseIcon";

const ContactInput = ({
  name,
  disabled,
  className,
  inputProps,
  buttonProps,
  ...rest
}) => {
  const [field, { error, touched }] = useField(name);
  return (
    <>
      <div className={clsx("relative", className)} {...rest}>
        <input
          name={name}
          disabled={disabled}
          className={clsx(
            "resize-none bg-white border py-2 pl-3 pr-10 border-gray-300 focus:outline-none focus:border-gray-400 disabled:bg-gray-100 disabled:opacity-60 block w-full",
            { "border-red-600 focus:border-red-700": error && touched },
            className
          )}
          {...field}
          {...inputProps}
        />
        <div className="absolute right-3 inset-y-0 flex items-center">
          <button type="button" disabled={disabled} {...buttonProps}>
            <CloseIcon className="w-6" />
          </button>
        </div>
      </div>
      {error && touched && <div className="mt-1 text-red-600">{error}</div>}
    </>
  );
};

export default ContactInput;
